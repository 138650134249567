import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { Product, ProductColor } from '../../../../interfaces/product';
import { QuickviewService } from '../../../../services/quickview.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { filter, finalize, switchMap, takeUntil } from 'rxjs/operators';
import { UrlService } from '../../../../services/url.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from '../../../../services/cart.service';
import { NavigationStart, Router } from '@angular/router';
import { ShopApi } from 'projects/storefront/src/app/api';
import { BuyNowService } from 'projects/storefront/src/app/services/buy-now.service';

@Component({
    selector: 'app-quickview',
    templateUrl: './quickview.component.html',
    styleUrls: ['./quickview.component.scss'],
})
export class QuickviewComponent implements OnDestroy, AfterViewInit {
    private destroy$: Subject<void> = new Subject();

    showGallery = false;

    product: Product = null;

    colors: ProductColor[] = [];

    form: FormGroup;

    addToCartInProgress = false;

    @ViewChild('modal') modal: ModalDirective;

    constructor(
        private fb: FormBuilder,
        private quickview: QuickviewService,
        private translate: TranslateService,
        private cart: CartService,
        private buyNow: BuyNowService,
        private router: Router,
        public url: UrlService,
        private shop: ShopApi,
    ) { }

    ngAfterViewInit(): void {
        this.quickview.show$.pipe(
            switchMap(product => {
                this.modal.show();
                this.product = product;

                this.form = this.fb.group({
                    quantity: [1, [Validators.required]],
                });

                this.shop.getProductColors(this.product.ItemModelId).subscribe(res => this.colors = res);

                // We are waiting for when the content will be rendered.
                // 150 = BACKDROP_TRANSITION_DURATION
                return timer(150);
            }),
            takeUntil(this.destroy$),
        ).subscribe(() => {
            // Show gallery only after content is rendered.
            this.showGallery = this.product !== null;
        });

        this.router.events.pipe(
            filter(event => event instanceof NavigationStart),
            takeUntil(this.destroy$),
        ).subscribe(() => {
            if (this.modal && this.modal.isShown) {
                this.modal.hide();
            }
        });

        this.modal.onHidden.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.product = null;
            this.showGallery = false;
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    addToCart(): void {
        if (this.addToCartInProgress) {
            return;
        }
        if (this.form.get('quantity').invalid) {
            alert(this.translate.instant('ERROR_ADD_TO_CART_QUANTITY'));
            return;
        }

        this.addToCartInProgress = true;

        this.cart.add(this.product, this.form.get('quantity').value).pipe(
            finalize(() => this.addToCartInProgress = false),
        ).subscribe();
    }
}
