<ng-container *ngIf="productPage">
  <a
    href="https://runnerautomobiles.com"
    target="_blank"
    rel="noopener"
    class="logo"
  >
    <div class="logo__slogan">
      {{ "TEXT_SLOGAN" | translate }}
    </div>
    <div class="logo__image">
      <!-- logo -->
      <img src="assets/images/logo.png" alt="Runner" />
      <!-- logo / end -->
    </div>
  </a>
</ng-container>
<ng-container *ngIf="sparePage">
  <a routerLink="/" class="logo">
    <div class="logo__image">
      <!-- logo -->
      <img src="assets/images/motoparts-logo.png" alt="Runner" />
      <!-- logo / end -->
    </div>
  </a>
</ng-container>
<ng-container *ngIf="accessoryPage">
  <a routerLink="/" class="logo">
    <div class="logo__image">
      <!-- logo -->
      <img src="assets/images/motogears-logo.png" alt="Runner" />
      <!-- logo / end -->
    </div>
  </a>
</ng-container>
