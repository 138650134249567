import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
    productPage = true;
    sparePage = false;
    accessoryPage = false;

    constructor(private router: Router) { }

    ngOnInit(): void {
        switch (this.router.url) {
            case '/shop/category/products':
                this.sparePage = false;
                this.accessoryPage = false;
                this.productPage = true;
                break;
            case '/shop/category/spares':
                this.sparePage = true;
                this.accessoryPage = false;
                this.productPage = false;
                break;
            case '/shop/category/accessories':
                this.sparePage = false;
                this.accessoryPage = true;
                this.productPage = false;
                break;
        }
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                switch (event.url) {
                    case '/shop/category/products':
                        this.sparePage = false;
                        this.accessoryPage = false;
                        this.productPage = true;
                        break;
                    case '/shop/category/spares':
                        this.sparePage = true;
                        this.accessoryPage = false;
                        this.productPage = false;
                        break;
                    case '/shop/category/accessories':
                        this.sparePage = false;
                        this.accessoryPage = true;
                        this.productPage = false;
                        break;
                    default:
                        this.sparePage = false;
                        this.accessoryPage = false;
                        this.productPage = true;
                        break;
                }
            }
        })
    }
}
