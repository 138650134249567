<div class="card__loader"></div>
<div *ngIf="label" class="address-card__badge tag-badge tag-badge--theme">{{ label }}</div>
<div *ngIf="address.type" class="address-card__type tag-badge tag-badge--new">{{ address.type }}</div>
<div class="address-card__body">
    <div class="address-card__name">{{ address.name }}</div>
    <div class="address-card__row">
        {{ address.address_line_1 }} {{ address.address_line_2 }} <br>
        {{ address.area }}, {{address.district}}<br>
         {{address.division}} - {{ address.zip }}<br>
    </div>
    <div class="address-card__row">
        <div class="address-card__row-title">{{ 'TEXT_PHONE_NUMBER'|translate }}</div>
        <div class="address-card__row-content">{{ address.mobile }}</div>
    </div>
    <div *ngIf="address.email" class="address-card__row">
        <div class="address-card__row-title">{{ 'TEXT_EMAIL_ADDRESS'|translate }}</div>
        <div class="address-card__row-content">{{ address.email }}</div>
    </div>
    <div class="address-card__footer" *ngIf="footer">
        <ng-container *ngTemplateOutlet="footer"></ng-container>
    </div>
</div>