import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

declare const fbq: any;
declare const dataLayer: any;

@Injectable({
  providedIn: 'root'
})
export class SocialTrackingService {
  isBrowser;

  constructor(
    @Inject(PLATFORM_ID) private platform
  ) {
    this.isBrowser = isPlatformBrowser(this.platform);
  }

  fbqPageTrack() {
    if (this.isBrowser) {
      fbq('track', 'PageView')
    }
  }

  gtmPageTrack() {
    if (this.isBrowser) {
      dataLayer.push({'event': 'PageView'});
    }
  }
}
