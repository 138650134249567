import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private cookieService: CookieService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // Get the XCSF token from cookie
        const token = this.cookieService.get('XSRF-TOKEN');

        // Clone the request and replace the original headers with
        // cloned headers, updated with the authorization.
        const authReq = req.clone({
            headers: req.headers.set('X-XSRF-TOKEN', token),
            withCredentials: true
        });

        // send cloned request with header to the next handler.
        return next.handle(authReq);
    }
}