import { Observable } from 'rxjs';
import { User } from '../../interfaces/user';
import { Address, AddressData } from '../../interfaces/address';
import { OrdersList } from '../../interfaces/list';
import { Order } from '../../interfaces/order';
import { JsonResponse } from '../../interfaces/common';

export interface EditProfileData {
    name: string;
    email: string;
    mobile: string;
}

export interface GetOrdersListOptions {
    page?: number;
    limit?: number;
    sort?: string;
}

export interface EditAddressData extends AddressData {
    default: boolean;
    status: string;
}

export abstract class AccountApi {
    abstract redirectTo: string;
    
    abstract user: User | null;

    abstract user$: Observable<User | null>;

    abstract signIn(username: string, password: string, remember: boolean): Observable<User>;

    abstract signUp(name: string, mobile: string, code: string, password: string): Observable<User>;

    abstract signOut(): Observable<void>;

    abstract checkIsEmailMobileExist(userId?: number, mobile?: string, email?: string): Observable<JsonResponse>;

    abstract sendVerificationCode(mobile: string): Observable<JsonResponse>;

    abstract forgotPassword(mobile: string): Observable<JsonResponse>;

    abstract resetPassword(mobile: string, code: string, password: string): Observable<JsonResponse>;

    abstract me(): Observable<User>;

    abstract editProfile(userId: number, data: EditProfileData): Observable<User>;

    abstract changePassword(userId: number, oldPassword: string, newPassword: string): Observable<void>;

    abstract changeAvatar(userId: number, data: FormData): Observable<User>;

    abstract addAddress(data: EditAddressData): Observable<Address>;

    abstract editAddress(addressId: number, data: EditAddressData): Observable<Address>;

    abstract delAddress(addressId: number): Observable<void>;

    abstract getDefaultAddress(): Observable<Address>;

    abstract getAddress(addressId: number): Observable<Address>;

    abstract getAddresses(): Observable<Address[]>;

    abstract getOrdersList(options?: GetOrdersListOptions): Observable<OrdersList>;

    abstract getOrderById(id: number): Observable<Order>;

    abstract getOrderByToken(token: string): Observable<Order>;
}
