import { Component, HostBinding } from '@angular/core';
import { theme } from '../../../../../data/theme';

@Component({
    selector: 'app-newsletter',
    templateUrl: './newsletter.component.html',
    styleUrls: ['./newsletter.component.scss'],
})
export class NewsletterComponent {
    @HostBinding('class.footer-newsletter') classFooterNewsletter = true;

    theme = theme;

    socialLinks = [
        {type: 'facebook',  url: 'https://www.facebook.com/RunnerMotorcycles', icon: 'fab fa-facebook-f'},
        {type: 'youtube',   url: 'https://www.youtube.com/channel/UCbeOj5wytm8IG6bqZNy2XEA?view_as=subscriber', icon: 'fab fa-youtube'},
        {type: 'instagram', url: 'https://www.instagram.com/runnermotorcycles', icon: 'fab fa-instagram'},
    ];

    constructor() { }
}
