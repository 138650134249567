import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { AccountApi, BlogApi, AddressApi, ShopApi, VehicleApi, HomeApi } from '../base';
import { RestAccountApi } from './rest-account.api';
import { RestShopApi } from './rest-shop.api';
import { RestHomeApi } from './rest-home.api';
import { RestAddressApi } from './rest-address.api';
import { RestVehicleApi } from './rest-vehicle.api';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    {provide: AccountApi, useClass: RestAccountApi},
    {provide: ShopApi, useClass: RestShopApi},
    {provide: HomeApi, useClass: RestHomeApi},
    {provide: AddressApi, useClass: RestAddressApi},
    {provide: VehicleApi, useClass: RestVehicleApi}
  ]
})
export class RestApiModule { }
