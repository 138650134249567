<h5 class="footer-contacts__title">{{ 'HEADER_CONTACT_US'|translate }}</h5>

<div class="footer-contacts__text">{{ 'TEXT_CONTACT_US_MESSAGE'|translate }}</div>

<address class="footer-contacts__contacts">
    <dl>
        <dt>{{ 'TEXT_PHONE_NUMBER'|translate }}</dt>
        <dd *ngFor="let item of theme.contacts.phone">{{ item }}</dd>
    </dl>
    <dl>
        <dt>{{ 'TEXT_EMAIL_ADDRESS'|translate }}</dt>
        <dd *ngFor="let item of theme.contacts.email">{{ item }}</dd>
    </dl>
    <dl>
        <dt>{{ 'TEXT_OUR_LOCATION'|translate }}</dt>
        <dd *ngFor="let item of theme.contacts.address">{{ item }}</dd>
    </dl>
    <!-- <dl>
        <dt>{{ 'TEXT_WORKING_HOURS'|translate }}</dt>
        <dd *ngFor="let item of theme.contacts.hours">{{ item }}</dd>
    </dl> -->
</address>
