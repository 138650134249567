import { Injectable } from '@angular/core';
import { VehicleApi } from '../base';
import { BehaviorSubject, Observable } from 'rxjs';
import { Vehicle } from '../../interfaces/vehicle';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';


@Injectable()
export class RestVehicleApi extends VehicleApi {
    userVehicles$: Observable<Vehicle[]>;
    currentVehicle$: Observable<Vehicle>;
    getYears(): Observable<number[]> {
        throw new Error("Method not implemented.");
    }
    getMakes(year: number): Observable<string[]> {
        throw new Error("Method not implemented.");
    }
    getModels(year: number, make: string): Observable<string[]> {
        throw new Error("Method not implemented.");
    }
    getVehicles(year: number, make: string, model: string): Observable<Vehicle[]> {
        throw new Error("Method not implemented.");
    }
    getVehicleByVin(vin: string): Observable<Vehicle> {
        throw new Error("Method not implemented.");
    }
    addUserVehicle(vehicleId: number): Observable<void> {
        throw new Error("Method not implemented.");
    }
    removeUserVehicle(vehicleId: number): Observable<void> {
        throw new Error("Method not implemented.");
    }
    private reloadUserVehicles: BehaviorSubject<void> = new BehaviorSubject<void>(null);

    private currentVehicleSubject: BehaviorSubject<Vehicle> = new BehaviorSubject<Vehicle>(null);


    constructor() {
        super();
    }

    setCurrentVehicle(vehicle: Vehicle): void {
        this.currentVehicleSubject.next(vehicle);
    }
}
