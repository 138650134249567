<div class="site-footer">
    <app-decor class="site-footer__decor" type="bottom"></app-decor>
    <div class="site-footer__widgets">
        <div class="container">
            <div class="row">
                <div class="col-12 col-xl-4">
                    <app-contacts class="site-footer__widget"></app-contacts>
                </div>
                <div class="col-6 col-md-3 col-xl-2">
                    <app-links class="site-footer__widget" [header]="'HEADER_INFORMATION'|translate" [links]="[
                            {title: 'LINK_ABOUT_US'|translate, url: 'https://runnerautomobiles.com/about-us', external: true},
                            {title: 'LINK_TERM_OF_USE'|translate, url: '/site/terms-of-use'},
                            {title: 'LINK_PRIVACY_POLICY'|translate, url: '/site/privacy-policy'},
                            {title: 'LINK_CONTACT_US'|translate, url: '/site/contact-us'},
                            {title: 'LINK_SITE_MAP'|translate}
                        ]"></app-links>
                </div>
                <div class="col-6 col-md-3 col-xl-2">
                    <app-links class="site-footer__widget" [header]="'HEADER_SERVICE_LINKS'|translate" [links]="[
                            {title: 'HEADER_MY_ACCOUNT'|translate, url: '/account/login'},
                            {title: 'LINK_FIND_SHOWROOM'|translate, url: '/site/showroom'},
                            {title: 'LINK_FIND_SERVICE_CENTER'|translate, url: '/site/service-center'},
                            {title: 'LINK_COMPARE_PRODUCT'|translate, url: '/shop/compare'}
                        ]"></app-links>
                </div>
                <div class="col-12 col-md-6 col-xl-4">
                    <app-newsletter class="site-footer__widget"></app-newsletter>
                </div>
            </div>
        </div>
    </div>
    <div class="site-footer__bottom">
        <div class="container">
            <div class="site-footer__bottom-row">
                <div class="site-footer__copyright text-center">
                    <!-- copyright -->
                    &copy; {{ year }} - Runner Automobiles Ltd.
                    <!-- copyright / end -->
                </div>
                <div class="site-footer__payments">
                    <img src="assets/images/payments.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>