<!-- mobile-logo -->
<!-- <img src="assets/images/logo.png" alt="Runner" width="130"> -->
<!-- mobile-logo / end -->
<a routerLink="/" class="logo">
  <ng-container *ngIf="productPage">
    <div class="logo__image">
      <!-- logo -->
      <img src="assets/images/logo.png" alt="Runner" width="130" />
      <!-- logo / end -->
    </div>
  </ng-container>
  <ng-container *ngIf="sparePage">
    <div class="logo__image">
      <!-- logo -->
      <img src="assets/images/motoparts-logo.png" alt="Runner" width="70" />
      <!-- logo / end -->
    </div>
  </ng-container>
  <ng-container *ngIf="accessoryPage">
    <div class="logo__image">
      <!-- logo -->
      <img src="assets/images/motogears-logo.png" alt="Runner" width="90" />
      <!-- logo / end -->
    </div>
  </ng-container>
</a>
