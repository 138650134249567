import { Observable } from 'rxjs';
import { Area, District, Division } from '../../interfaces/address';

export abstract class AddressApi {
  
  abstract getDivisions(): Observable<Division[]>

  abstract getDistricts(division: number): Observable<District[]>;

  abstract getAreas(district: number): Observable<Area[]>;

}