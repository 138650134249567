import { CustomFields } from './custom-fields';
import { Brand } from './brand';
import { ShopCategory } from './category';
export interface BaseAttributeGroup {
    name: string;
    slug: string;
    customFields?: CustomFields;
}

export type ProductAttributeGroup = BaseAttributeGroup & { attributes: ProductAttribute[]; };
export type ProductTypeAttributeGroup = BaseAttributeGroup & { attributes: string[]; };

export enum ProductType {
    BIKE = 'bike',
    SPARE = 'spare',
    ACCESSORY = 'accessory'
}

export enum BikeType {
    PREMIUM = 'premium',
    STANDARD = 'standard',
}

export interface ProductAttributeValue {
    name: string;
    slug: string;
    customFields?: CustomFields;
}

export interface ProductAttribute {
    name: string;
    slug: string;
    featured: boolean;
    values: ProductAttributeValue[];
    customFields?: CustomFields;
}

export interface ProductOptionValueBase {
    name: string;
    slug: string;
    customFields?: CustomFields;
}

export interface ProductOptionValueColor extends ProductOptionValueBase {
    color: string;
}

export interface ProductOptionBase {
    type: string;
    name: string;
    slug: string;
    values: ProductOptionValueBase[];
    customFields?: CustomFields;
}

export interface ProductOptionDefault extends ProductOptionBase {
    type: 'default';
}

export interface ProductOptionColor extends ProductOptionBase {
    type: 'color';
    values: ProductOptionValueColor[];
}

export type ProductOption = ProductOptionDefault | ProductOptionColor;

export type ProductStock = 'in-stock' | 'out-of-stock' | 'on-backorder';

export type ProductCompatibilityResult = 'all' | 'fit' | 'not-fit' | 'unknown';

export interface ProductTab {
    icon: string;
    title: string;
    content: string;
}

export interface ProductColor {
    id: number;
    slug: string;
    color: string;
    color_code: string;
}

export interface ProductEmiInfo {
    product_id: number;
    InsuranceCharge: number,
    RegCharge: number,
    InterestRate1: number,
    InterestRate2: number,
    InterestRate3: number,
    InterestRate4: number,
    InterestRate5: number,
    DPParctent1: number,
    DPParctent2: number,
    DPParctent3: number,
    DPParctent4: number,
    DPParctent5: number,
    MinimumDP: number,
}

export interface ProductEmiPrices {
    price: number,
    includeReg: boolean,
    regInsuranceCharge: number,
    downPayment: number,
    month: number,
    monthlyInterest: number,
    payable: number,
}

export interface ProductSliderImage {
    title: string;
    image: string;
    thumbnail: string;
}

export interface ProductSlider {
    image: string;
    images: ProductSliderImage[]
}

export interface PremiumProductSlider {
    image: string;
    images: Array<string[]>
}

export interface PremiumProductTimeline {
    image: string;
    title: string;
    excerpt: string;
}

export interface PremiumProductSpecification {
    icon: string;
    title: string;
    subtitle: string;
}

export interface PremiumRelated {
    id: number;
    slug: string;
    name: string;
    image: string;
    type: string;
    bikeType: string;
    model: string;
    sku: string;
}

export interface Product {
    id: number;
    name: string;
    /**
     * A short product description without HTML tags.
     */
    excerpt: string;
    description: string;
    slug: string;
    sku?: string;
    partNumber: string;
    stock: ProductStock;
    price: number;
    compareAtPrice: number | null;
    discountedPrice: number | null;
    discount: number | null;
    additionalDiscount: number | null;
    hasEmi: boolean;
    cc?: number;
    images?: string[];
    badges?: string[];
    rating?: number;
    reviews?: number;
    availability?: string;
    image?: string;
    bannerImage?: string;
    specSideImage?: string;
    premiumSliderBg?: string;
    premiumSpecVideo?: string;
    /**
     * 'all'     - Compatible with all vehicles.
     * 'unknown' - No compatibility information. Part may not fit the specified vehicle.
     * number[]  - An array of vehicle identifiers with which this part is compatible.
     */
    compatibility: 'all' | 'unknown' | number[];
    brand?: Brand | null;
    tags?: string[];
    type: ProductType;
    bikeType?: BikeType;
    categories?: ShopCategory[];
    category?: ShopCategory;
    attributes?: ProductAttribute[];
    options?: ProductOption[];
    customFields?: CustomFields;
    ItemModelId?: number;
    tabs?: ProductTab[];
}
