import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountApi, EditAddressData, EditProfileData, GetOrdersListOptions } from '../base';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../../interfaces/user';
import { tap, switchMap, catchError } from 'rxjs/operators';
import { Address } from '../../interfaces/address';
import { OrdersList } from '../../interfaces/list';
import { Order } from '../../interfaces/order';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { JsonResponse } from '../../interfaces/common';


@Injectable()
export class RestAccountApi extends AccountApi {
    redirectTo: string;


    getOrderByToken(token: string): Observable<Order> {
        throw new Error("Method not implemented.");
    }
    private url = `${environment.apiUrl}/customer`;

    private isBrowser: boolean;
    private userSubject: BehaviorSubject<User | null>;

    get user(): User | null { return this.userSubject.value; }

    readonly user$: Observable<User | null>;

    constructor(
        private cookieService: CookieService,
        private http: HttpClient,
    ) {
        super();

        const storedUser = this.cookieService.get('user');

        this.userSubject = new BehaviorSubject<User | null>(storedUser ? JSON.parse(storedUser) : null);
        this.user$ = this.userSubject.asObservable();
    }

    signIn(username: string, password: string, remember: boolean): Observable<User> {
        return this.http.get(`${environment.apiBaseUrl}/sanctum/csrf-cookie`).pipe(
            switchMap(() => {
                return this.http.post<User>(`${this.url}/login`, { username, password, remember }).pipe(
                    tap(user => this.setUser(user)),
                );
            })
        );
    }

    signUp(name: string, mobile: string, code: string, password: string): Observable<User> {
        return this.http.get(`${environment.apiBaseUrl}/sanctum/csrf-cookie`).pipe(
            switchMap(() => {
                return this.http.post<User>(`${this.url}/register`, { name, mobile, code, password }).pipe(
                    tap(user => this.setUser(user)),
                );
            })
        );
    }

    signOut(): Observable<void> {
        return this.http.get<void>(`${this.url}/logout`).pipe(
            tap(() => this.setUser(null)),
            catchError(async () => this.setUser(null))
        );
    }

    checkIsEmailMobileExist(userId?: number, mobile?: string, email?: string): Observable<JsonResponse> {
        return this.http.get<JsonResponse>(`${this.url}/email-mobile`, {
            params: {
                user_id: userId ? userId.toString() : '',
                mobile: mobile ? mobile : '',
                email: email ? email : '',
            }
        });
    }

    sendVerificationCode(mobile: string): Observable<JsonResponse> {
        return this.http.get<JsonResponse>(`${this.url}/verification-code/${mobile}`);
    }

    forgotPassword(mobile: string): Observable<JsonResponse> {
        return this.http.get<JsonResponse>(`${this.url}/forgot-password/${mobile}`);
    }

    resetPassword(mobile: string, code: string, password: string): Observable<JsonResponse> {
        return this.http.post<JsonResponse>(`${this.url}/reset-password`, { mobile, code, password });
    }

    me(): Observable<User> {
        return this.http.get<User>(`${this.url}/me`).pipe(
            tap((user) => this.setUser(user)),
        );
    }

    editProfile(userId: number, data: EditProfileData): Observable<User> {
        return this.http.post<User>(`${this.url}/update-info/${userId}`, data);
    }

    changePassword(userId: number, oldPassword: string, newPassword: string): Observable<void> {
        return this.http.post<void>(`${this.url}/update-password/${userId}`, {
            currentPassword: oldPassword,
            password: newPassword,
        });
    }

    changeAvatar(userId: number, data: FormData): Observable<User> {
        return this.http.post<User>(`${this.url}/update-avatar/${userId}`, data);
    }

    addAddress(data: EditAddressData): Observable<Address> {
        return this.http.post<Address>(`${this.url}/address`, data);
    }

    editAddress(addressId: number, data: EditAddressData): Observable<Address> {
        return this.http.patch<Address>(`${this.url}/address/${addressId}`, data);
    }

    delAddress(addressId: number): Observable<void> {
        return this.http.delete<void>(`${this.url}/address/${addressId}`);
    }

    getDefaultAddress(): Observable<Address> {
        return this.http.get<Address>(`${this.url}/get-default-address`);
    }

    getAddress(addressId: number): Observable<Address> {
        return this.http.get<Address>(`${this.url}/address/${addressId}`);
    }

    getAddresses(): Observable<Address[]> {
        return this.http.get<Address[]>(`${this.url}/address`);
    }

    getOrdersList(options?: GetOrdersListOptions): Observable<OrdersList> {
        return this.http.get<OrdersList>(`${this.url}/order`, {
            params: {
                page: options.page ? options.page.toString() : '1',
                limit: options.limit ? options.limit.toString() : '16',
                sort: options.sort ? options.sort : '',
            }
        });
    }

    getOrderById(id: number): Observable<Order> {
        return this.http.get<Order>(`${this.url}/order/${id}`);
    }

    private setUser(user: User): void {
        this.userSubject.next(user);

        this.cookieService.set('user', JSON.stringify(user), 0.08333, '/');
    }
}
