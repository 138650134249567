import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { HomeApi } from '../base/home.api';
import { environment } from '../../../environments/environment';
import { LanguageService } from '../../modules/language/services/language.service';
import { HomeSlider } from '../../interfaces/home-slider';
import { SpecialOffer } from '../../interfaces/special-offer';
import { Showroom } from '../../interfaces/showroom';
import { TestRide } from '../../interfaces/ride';

@Injectable()
export class RestHomeApi extends HomeApi {
    private url = `${environment.apiUrl}/${this.language.current.code}/home`;
    private homeUrl = `${environment.apiUrl}/home`;

    constructor(
        private http: HttpClient,
        private language: LanguageService,
    ) {
        super();
    }

    getSliders(): Observable<HomeSlider[]> {
        return this.http.get<HomeSlider[]>(`${this.url}/slider`);
    }
    
    getSpecialOffer(): Observable<SpecialOffer> {
        return this.http.get<SpecialOffer>(`${this.url}/offer`);
    }

    getShowrooms(): Observable<Showroom[]> {
        return this.http.get<Showroom[]>(`${this.url}/showroom`);
    }

    getServiceCenters(): Observable<Showroom[]> {
        return this.http.get<Showroom[]>(`${this.url}/service-center`);
    }

    applyForTestRide(body: TestRide): Observable<any> {
        return this.http.post(`${this.homeUrl}/ride`, body);
    }
}