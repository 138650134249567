<ng-container [formGroup]="form">
    <div class="form-group">
        <label for="{{ formId }}-name">{{ 'INPUT_NAME_LABEL'|translate }}</label>
        <input type="text" class="form-control" id="{{ formId }}-name" formControlName="name"
            [class.is-invalid]="form.controls.name|isInvalid" [placeholder]="'INPUT_NAME_PLACEHOLDER'|translate">
        <div class="invalid-feedback">
            <div *ngIf="form.controls.name|hasError:'required'">
                {{ 'ERROR_FORM_REQUIRED'|translate }}
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="{{ formId }}-email">{{ 'INPUT_EMAIL_ADDRESS_LABEL'|translate }}</label>
            <input type="email" class="form-control" id="{{ formId }}-email" formControlName="email"
                [class.is-invalid]="form.controls.email|isInvalid"
                [placeholder]="'INPUT_EMAIL_ADDRESS_PLACEHOLDER'|translate">
            <div class="invalid-feedback">
                <div *ngIf="form.controls.email|hasError:'required'">
                    {{ 'ERROR_FORM_REQUIRED'|translate }}
                </div>
                <div *ngIf="form.controls.email|hasError:'email'">
                    {{ 'ERROR_FORM_INCORRECT_EMAIL'|translate }}
                </div>
            </div>
        </div>
        <div class="form-group col-md-6">
            <label for="{{ formId }}-mobile">{{ 'INPUT_PHONE_NUMBER_LABEL'|translate }}</label>
            <input type="text" class="form-control" id="{{ formId }}-mobile" formControlName="mobile"
                [class.is-invalid]="form.controls.mobile|isInvalid"
                [placeholder]="'INPUT_PHONE_NUMBER_PLACEHOLDER'|translate">
            <div class="invalid-feedback">
                <div *ngIf="form.controls.mobile|hasError:'required'">
                    {{ 'ERROR_FORM_REQUIRED'|translate }}
                </div>
                <div *ngIf="form.controls.mobile|hasError:'mobile'">
                    {{ 'ERROR_MOBILE_FORMAT'|translate }}
                </div>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label for="{{ formId }}-address_line_1">{{ 'INPUT_STREET_ADDRESS_LABEL'|translate }}</label>
        <input type="text" class="form-control" id="{{ formId }}-address_line_1" formControlName="address_line_1"
            [class.is-invalid]="form.controls.address_line_1|isInvalid"
            [placeholder]="'INPUT_STREET_ADDRESS_PLACEHOLDER_1'|translate">
        <div class="invalid-feedback">
            <div *ngIf="form.controls.address_line_1|hasError:'required'">
                {{ 'ERROR_FORM_REQUIRED'|translate }}
            </div>
        </div>
        <label for="{{ formId }}-address_line_2" class="sr-only">{{ 'INPUT_STREET_ADDRESS_LABEL'|translate }}</label>
        <input type="text" class="form-control mt-2" id="{{ formId }}-address_line_2" formControlName="address_line_2"
            [placeholder]="'INPUT_STREET_ADDRESS_PLACEHOLDER_2'|translate">
    </div>
    <div class="form-row">
        <div class="form-group col-xs-12 col-md-6">
            <label for="{{ formId }}-division">{{ 'INPUT_DIVISION_LABEL'|translate }}</label>
            <select id="{{ formId }}-division" class="form-control" formControlName="division_id"
                [class.is-invalid]="form.controls.division_id|isInvalid">
                <option value="">{{ 'INPUT_DIVISION_PLACEHOLDER'|translate }}</option>
                <option *ngFor="let division of divisions" [value]="division.id">
                    {{ division.name }}
                </option>
            </select>
            <div class="invalid-feedback">
                <div *ngIf="form.controls.division_id|hasError:'required'">
                    {{ 'ERROR_FORM_REQUIRED'|translate }}
                </div>
            </div>
        </div>
        <div class="form-group col-xs-12 col-md-6">
            <label for="{{ formId }}-district">{{ 'INPUT_DISTRICT_LABEL'|translate }}</label>
            <select id="{{ formId }}-district" class="form-control" formControlName="district_id"
                [class.is-invalid]="form.controls.district_id|isInvalid">
                <option value="">{{ 'INPUT_DISTRICT_PLACEHOLDER'|translate }}</option>
                <option *ngFor="let district of districts" [value]="district.id">
                    {{ district.name }}
                </option>
            </select>
            <div class="invalid-feedback">
                <div *ngIf="form.controls.district_id|hasError:'required'">
                    {{ 'ERROR_FORM_REQUIRED'|translate }}
                </div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-xs-12 col-md-6 mb-0">
            <label for="{{ formId }}-area">{{ 'INPUT_AREA_LABEL'|translate }}</label>
            <select id="{{ formId }}-area" class="form-control" formControlName="area_id"
                [class.is-invalid]="form.controls.area_id|isInvalid">
                <option value="">{{ 'INPUT_AREA_PLACEHOLDER'|translate }}</option>
                <option *ngFor="let area of areas" [value]="area.id">
                    {{ area.name }}
                </option>
            </select>
            <div class="invalid-feedback">
                <div *ngIf="form.controls.area_id|hasError:'required'">
                    {{ 'ERROR_FORM_REQUIRED'|translate }}
                </div>
            </div>
        </div>
        <div class="form-group col-xs-12 col-md-6 mb-0">
            <label for="{{ formId }}-zip">{{ 'INPUT_POSTCODE_LABEL'|translate }}</label>
            <input type="text" class="form-control" id="{{ formId }}-zip" formControlName="zip"
                [class.is-invalid]="form.controls.zip|isInvalid" [placeholder]="'INPUT_POSTCODE_PLACEHOLDER'|translate">
            <div class="invalid-feedback">
                <div *ngIf="form.controls.zip|hasError:'required'">
                    {{ 'ERROR_FORM_REQUIRED'|translate }}
                </div>
            </div>
        </div>
    </div>
</ng-container>